<template>
  <div class="result">
    <div>
      <a-icon :class="[isSuccess ? 'success' : 'error' ,'icon']" :type="isSuccess ? 'check-circle' : 'close-circle'"/>
    </div>
    <div class="title" v-if="title">{{ title }}</div>
    <div class="description" v-if="description">{{ description }}</div>
    <div class="content" v-if="content">
      <slot></slot>
    </div>
    <div class="action">
      <slot name="action"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Result",
    // 'isSuccess', 'title', 'description'
    props: {
      isSuccess: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: ''
      },
      description: {
        type: String,
        default: ''
      },
      content: {
        type: Boolean,
        default: true
      }
    }
  }
</script>

<style lang="less" scoped>
  .data {
    text-align: center;
    width: 72%;
    margin: 0 auto;
    padding: 24px 0 8px;

    .icon {
      font-size: 72px;
      line-height: 72px;
      margin-bottom: 24px;
    }
    .success {
      color: #52c41a;
    }
    .error {
      color: red;
    }
    .title {
      font-size: 24px;
      color: rgba(0, 0, 0, .85);
      font-weight: 500;
      line-height: 32px;
      margin-bottom: 16px;
    }
    .description {
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 24px;
    }
    .content {
      background: #fafafa;
      padding: 24px 40px;
      border-radius: 2px;
      text-align: left;
    }
    .action {
      margin-top: 32px;
    }
  }

  .mobile {
    .data {
      width: 100%;
      margin: 0 auto;
      padding: unset;
    }
  }
</style>