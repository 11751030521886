var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "a-form",
        { staticStyle: { margin: "40px auto 0" } },
        [
          _c(
            "result",
            { attrs: { title: "更改密码成功", "is-success": true } },
            [
              _c("div", { staticClass: "toLogin" }, [
                _c("h3", [
                  _vm._v("将在"),
                  _c("span", [_vm._v(_vm._s(_vm.time))]),
                  _vm._v("秒后返回登录页面."),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }